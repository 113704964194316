import { useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useMutation } from 'react-query';

import {
  Message,
  Button,
  Divider,
  Container,
  Header,
  Segment,
} from 'semantic-ui-react';

import useReturn from '../hooks/useReturn';

import { get as getCookie } from 'js-cookie';
import axios from 'axios';

function ActivateAccount() {
  const { uid, token } = useParams();

  const activate = useMutation(async () => {
    try {
      const { data } = await axios.post(
        '/api/auth/users/activation/',
        {
          uid,
          token,
        },
        {
          headers: {
            'X-CSRFToken': getCookie('csrftoken'),
          },
        }
      );
      return data;
    } catch (error) {
      throw new Error(JSON.stringify(error.response.data));
    }
  });

  // activate immediately
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => activate.mutate(), []);

  const handleReturn = useReturn();
  const location = useLocation();

  return (
    <main className="no-header">
      <Container text as={Segment}>
        <Header as="h2" textAlign="center" content="Ενεργοποίηση λογαριασμού" />

        {activate.isError && (
          <Message
            error
            header="Αποτυχία ενεργοποίησης λογαριασμου."
            content="Πιθανόν να είναι ήδη ενεργοποιημένος. Εναλλακτικά, ξαναπροσπαθήστε αργότερα."
          />
        )}
        {activate.isSuccess && (
          <Message success>
            <Message.Header>
              Ο λογαριασμός σας ενεργοποιήθηκε επιτυχώς!
            </Message.Header>
          </Message>
        )}

        <Divider />

        {activate.isError && (
          <div style={{ textAlign: 'center'}}>
            <Button
              style={{ marginTop: '1em', minWidth: '10em' }}
              size="large"
              primary
              content="Επανάληψη"
              onClick={() => activate.mutate()}
            />
            {handleReturn && (
              <Button
                style={{ marginTop: '1em', minWidth: '10em' }}
                size="large"
                content="Επιστροφή"
                onClick={handleReturn}
              />
            )}
          </div>
        )}

        {activate.isSuccess && (
          <Button
            size="large"
            primary
            content="Σύνδεση"
            as={Link}
            to={{ pathname: '/login', search: location.search }}
            replace
          />
        )}
      </Container>
    </main>
  );
}

export default ActivateAccount;
