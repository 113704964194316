import { Fragment, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  generatePath,
  Link,
  Redirect,
  Route,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Message,
  Ref,
  Segment,
  Sticky,
  Table,
} from 'semantic-ui-react';
import { DateFilter, OrientedPagination } from '@zerowaste/components';

import ParticipantsList from './ParticipantsList';

import { usePointsHistory, usePointsHistoryExport } from '../queries/points';
import { useProfile } from '../queries/useProfile';

import omit from 'lodash/omit';
import dayjs from 'dayjs';

const DESCRIPTION_ENUM = {
  'cancelled coupon': 'Ακύρωση κουπονιού επιβράβευσης',
  'reimbursed coupon': 'Ακύρωση κουπονιού επιβράβευσης',
  'created coupon': 'Έκδοση κουπονιού επιβράβευσης',
  'collected oil': 'Παράδοση τηγανελαίων',
  'cancelled collection': 'Ακύρωση παραλαβής τηγανέλαιων',
  'reused item': 'Επαναχρησιμοποίηση αντικειμένου',
  'delivered item': 'Παράδοση αντικειμένου για επαναχρησιμοποίηση',
};

function HistoryTableDesktop({ points, onFilterReset }) {
  return (
    <Table
      celled
      selectable
      style={{
        opacity: points.isPreviousData && points.isFetching ? '0.5' : '1',
      }}
    >
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Χρήστης</Table.HeaderCell>
          <Table.HeaderCell>Ημερομηνία</Table.HeaderCell>
          <Table.HeaderCell>Περιγραφή</Table.HeaderCell>
          <Table.HeaderCell>Πόντοι</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {points.data?.results.map(
          ({ id, participant, points, created_at, description }) => (
            <Table.Row key={id}>
              <Table.Cell>
                <Link to={`/admin/users/${participant.id}`}>
                  {participant.email || 'Άγνωστος χρήστης'}
                </Link>
              </Table.Cell>
              <Table.Cell>
                {new Date(created_at).toLocaleString('el')}
              </Table.Cell>
              <Table.Cell>{DESCRIPTION_ENUM[description]}</Table.Cell>
              <Table.Cell>{points}</Table.Cell>
            </Table.Row>
          )
        )}
        {points.data?.count === 0 && (
          <Table.Row textAlign="center">
            <Table.Cell colSpan={4}>
              Δεν εντοπίστηκαν στοιχεία που ικανοποιούν τα φίλτρα που έχετε
              επιλέξει.
              <Divider hidden fitted />
              <Button onClick={onFilterReset}>Επαναφορά φίλτρων</Button>
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
}

function HistoryTableMobile({ points, onFilterReset }) {
  return (
    <div>
      <Table
        unstackable
        definition
        fixed
        style={{
          opacity: points.isPreviousData && points.isFetching ? '0.5' : '1',
        }}
      >
        <Table.Body>
          {points.data?.results.map(
            ({ id, participant, points, created_at, description }, index) => (
              <Fragment key={id}>
                {index > 0 && (
                  <Table.Row>
                    <Table.Cell
                      style={{ backgroundColor: 'white' }}
                      colSpan={2}
                    />
                  </Table.Row>
                )}

                <Table.Row>
                  <Table.Cell>Χρήστης</Table.Cell>
                  <Table.Cell>
                    <Link to={`/admin/users/${participant.id}`}>
                      {participant?.email || 'Άγνωστος χρήστης'}
                    </Link>
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>Ημερομηνία</Table.Cell>
                  <Table.Cell>
                    {new Date(created_at).toLocaleString('el')}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Περιγραφή</Table.Cell>
                  <Table.Cell>{DESCRIPTION_ENUM[description]}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Πόντοι</Table.Cell>
                  <Table.Cell>{points}</Table.Cell>
                </Table.Row>
              </Fragment>
            )
          )}

          {points.data?.count === 0 && (
            <Table.Row textAlign="center">
              <Table.Cell colSpan={2}>
                Δεν εντοπίστηκαν στοιχεία που ικανοποιούν τα φίλτρα που έχετε
                επιλέξει.
                <Divider hidden fitted />
                <Button onClick={onFilterReset}>Επαναφορά φίλτρων</Button>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </div>
  );
}

function HistoryTable({ points, onFilterReset }) {
  const stickyRef = useRef();

  const history = useHistory();
  const { page } = useParams();

  if (points.isLoading) {
    return <Segment basic placeholder loading />;
  }
  if (points.isError) {
    return (
      <Message
        error
        content="Οι πληροφορίες ιστορικού πόντων δεν είναι διαθέσιμες αυτή τη στιγμή."
      />
    );
  }

  const paginationProps = {
    activePage: page,
    totalPages: points.data?.total_pages,
    onPageChange: (e, { activePage }) =>
      history.push({ pathname: `${activePage}` }),
    disabled: points.data?.total_pages === 1,
  };
  const showPagination = points.data?.total_pages > 1;

  return (
    <Grid columns={1}>
      <Grid.Column only="mobile">
        <Ref innerRef={stickyRef}>
          <div style={{ textAlign: 'center' }}>
            {showPagination && (
              <Sticky context={stickyRef}>
                <OrientedPagination
                  {...paginationProps}
                  boundaryRange={1}
                  siblingRange={0}
                  size="large"
                  pointing={false}
                  secondary={false}
                />
              </Sticky>
            )}
            <HistoryTableMobile points={points} onFilterReset={onFilterReset} />
          </div>
        </Ref>
      </Grid.Column>
      <Grid.Column only="tablet computer">
        {showPagination && <OrientedPagination {...paginationProps} />}
        <HistoryTableDesktop points={points} onFilterReset={onFilterReset} />
      </Grid.Column>
    </Grid>
  );
}

function PointsHistory() {
  const history = useHistory();
  const match = useRouteMatch();
  const params = useParams();

  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  const dateParams = useMemo(() => {
    return {
      created_at_after: dateFrom
        ? dayjs(dateFrom).format('YYYY-MM-DD')
        : undefined,
      created_at_before: dateTo
        ? dayjs(dateTo).format('YYYY-MM-DD')
        : undefined,
    };
  }, [dateFrom, dateTo]);

  const { participantId, page } = params;

  const baseUrl = generatePath(match.path, omit(params, ['page', 'pageKey']));

  const pointsHistory = usePointsHistory({
    params: {
      page,
      page_size: 20,
      participant: participantId,
      ...dateParams,
    },
  });

  const resetPage = () => {
    history.replace(`${baseUrl}/page/1`);
  };

  // Export excel
  const exportExcel = usePointsHistoryExport({
    participant: participantId,
    ...dateParams,
  });

  const handleExportData = () => {
    exportExcel.refetch().then((response) => {
      const file = new File([response.data], 'Ιστορικό πόντων.xlsx', {
        type: response.data.type,
      });
      const href = URL.createObjectURL(file);
      window.location = href;
      URL.revokeObjectURL(href);
    });
  };

  const handleFilterReset = () => {
    setDateFrom(null);
    setDateTo(null);
    resetPage();
  };

  if (
    !pointsHistory.isPreviousData &&
    pointsHistory.data?.total_pages > 1 &&
    !page
  ) {
    return <Redirect to={`${baseUrl}/page/1`} />;
  }

  return (
    <Container>
      <Grid>
        <Grid.Row>
          <Grid.Column width={16} textAlign="right">
            <Button
              content="Εξαγωγή σε Excel"
              icon="download"
              onClick={handleExportData}
              loading={exportExcel.isFetching}
              disabled={exportExcel.isFetching}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <DateFilter
              dateFrom={dateFrom}
              setDateFrom={setDateFrom}
              dateTo={dateTo}
              setDateTo={setDateTo}
              onDateChange={resetPage}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <HistoryTable points={pointsHistory} onFilterReset={handleFilterReset} />
    </Container>
  );
}

export default function HistoryAdmin() {
  const match = useRouteMatch();

  const profile = useProfile();
  const staff = profile.data?.is_staff;

  const pageTitle = 'Ιστορικό πόντων';
  return (
    <Container>
      <Helmet title={pageTitle} />
      <Header
        as="h1"
        textAlign="center"
        className="colored atmgreen"
        content={pageTitle}
      />
      <Divider className="orange" />
      <Divider section hidden />

      <Grid>
        <Grid.Row>
          {staff && (
            <Grid.Column width={4}>
              <ParticipantsList
                parentPageTitle={pageTitle}
                parentMatch={match}
                filter
                as="div"
                listHeight="50vh"
              />
            </Grid.Column>
          )}

          <Grid.Column width={staff ? 12 : undefined}>
            <Route
              path={`${match.path}/:participantId(\\d+)?/:pageKey(page)?/:page(\\d+)?`}
            >
              <PointsHistory />
            </Route>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider hidden />
    </Container>
  );
}
