import { useEffect, useState } from 'react';
import { Route, useRouteMatch, Link, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useMutation, useQuery } from 'react-query';

import {
  Button,
  Container,
  Divider,
  Form,
  Header,
  Loader,
  Menu,
  Segment,
} from 'semantic-ui-react';

import { PasswordChangeForm } from './PasswordReset';

import { useProfile } from '../queries/useProfile';

import { get as getCookie } from 'js-cookie';
import axios from 'axios';

export function ParticipantForm(props) {
  const match = useRouteMatch();

  const { isFetched, isLoading, data: profile, refetch } = useProfile();

  const [formChanged, setFormChanged] = useState(false);
  const [profileData, setProfileData] = useState(() => ({
    municipality: null,
    telephone: '',
  }));

  useEffect(() => {
    if (isFetched) {
      setProfileData(profile);
      setFormChanged(false);
    }
  }, [isFetched, profile]);

  const saveMutation = useMutation(
    (data) =>
      axios
        .patch(`/api/auth/users/me/`, data, {
          headers: {
            'X-CSRFToken': getCookie('csrftoken'),
          },
        })
        .then(({ data }) => data),
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  const handleChange = (e, { name, value }) => {
    setFormChanged(true);
    setProfileData((d) => ({ ...d, [name]: value }));
  };

  const handleSubmit = () =>
    saveMutation.mutate({
      ...profileData,
      municipality: profileData.municipality || undefined,
      telephone: profileData.telephone || undefined,
    });
  const handleReset = () => {
    setProfileData(profile);
    setFormChanged(false);
  };

  const { data: municipalities } = useQuery(
    'municipalities',
    () =>
      axios.get('/api/municipalities/').then(({ data }) => {
        // transform to options format
        return data.map((m) => ({ value: m.id, text: m.name }));
      }),
    {
      initialData: [],
      enabled: profile?.is_staff === false,
    }
  );

  return (
    <Switch>
      <Route path={match.path} exact>
        <>
          {!props.inPage && (
            <>
              <Header
                as="h2"
                textAlign="center"
                content="Ρυθμίσεις λογαριασμού"
              />
              <Divider />
            </>
          )}

          <Container text as={Segment} disabled={isLoading} secondary>
            <Loader active={isLoading} />

            <Form size="large">
              <Form.Field inline label="Ηλεκτρονική διεύθυνση" control="strong">
                {profile?.email}
              </Form.Field>
            </Form>

            <Divider />

            <Form onSubmit={handleSubmit}>
              <Form.Input
                label="Όνομα"
                name="first_name"
                value={profileData.first_name}
                onChange={handleChange}
              />
              <Form.Input
                label="Επώνυμο"
                name="last_name"
                value={profileData.last_name}
                onChange={handleChange}
              />

              {profile?.is_staff === false && (
                <Form.Group>
                  <Form.Input
                    width={8}
                    label="Τηλέφωνο"
                    input={{ inputMode: 'tel' }}
                    name="telephone"
                    value={profileData.telephone}
                    onChange={handleChange}
                  />

                  <Form.Dropdown
                    width={8}
                    selection
                    label="Δήμος"
                    name="municipality"
                    value={profileData.municipality || null}
                    onChange={handleChange}
                    options={municipalities}
                  />
                </Form.Group>
              )}

              <Menu text>
                <Menu.Item>
                  <Button
                    secondary
                    content="Αλλαγή συνθηματικού"
                    as={Link}
                    to={`${match.url}/password`}
                  />
                </Menu.Item>

                <Menu.Menu position="right">
                  <Menu.Item>
                    <Form.Button
                      type="reset"
                      content="Επαναφορά"
                      onClick={handleReset}
                      disabled={!formChanged}
                    />
                  </Menu.Item>
                  <Menu.Item>
                    <Form.Button
                      primary
                      content="Ενημέρωση"
                      disabled={!formChanged}
                      loading={saveMutation.isLoading}
                    />
                  </Menu.Item>
                </Menu.Menu>
              </Menu>
            </Form>
          </Container>
        </>
      </Route>

      <Route path={`${match.path}/password`}>
        <PasswordChangeForm {...props} />
      </Route>
    </Switch>
  );
}

function ParticipantSettings() {
  return (
    <>
      <Helmet title="Ρυθμίσεις λογαριασμού" />
      <Container text>
        <ParticipantForm />
      </Container>

      <Divider hidden />
    </>
  );
}

export default ParticipantSettings;
