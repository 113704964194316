import { Route, NavLink, useParams, useRouteMatch } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import {
  Menu,
  Container,
  Header,
  Divider,
  Grid,
  Segment,
  Form,
  Input,
} from 'semantic-ui-react';

import { useParticipant } from '../queries/participants';
import ParticipantsList from './ParticipantsList';

function ParticipantDetails() {
  const match = useRouteMatch();
  const { participantId } = useParams();

  const participant = useParticipant(participantId);
  const participantDisplayName =
    participant.data && (participant.data.full_name || 'Άγνωστο όνομα');

  return (
    <>
      <Menu secondary pointing size="huge">
        <Menu.Item name="details" as={NavLink} to={match.url} exact>
          Στοιχεία χρήστη
        </Menu.Item>
      </Menu>

      <Segment
        secondary
        placeholder={participant.isLoading}
        loading={participant.isLoading}
      >
        <Header as="h3" dividing content={participantDisplayName} />
        <Form>
          <Form.Group widths={2}>
            <Form.Input
              readOnly
              label="Όνομα"
              value={participant.data?.first_name || ''}
            />
            <Form.Input
              readOnly
              label="Επώνυμο"
              value={participant.data?.last_name || ''}
            />
          </Form.Group>

          <Form.Group widths={2}>
            <Form.Input
              readOnly
              label="Τηλέφωνο"
              value={participant.data?.telephone || ''}
            />
            <Form.Input
              readOnly
              label="E-mail"
              value={participant.data?.email || ''}
            />
          </Form.Group>
          <Form.Input
            readOnly
            label="Δήμος"
            value={participant.data?.municipality_name || ''}
          />
          <Divider />
          <Form.Group widths="equal">
            <Form.Input
              readOnly
              label="Συνολικό υπόλοιπο πόντων"
              value={participant.data?.points_summary.available ?? ''}
            />
            <Form.Input
              readOnly
              label="Σύνολο πόντων που έχουν συλλεχθεί"
              value={participant.data?.points_summary.collected ?? ''}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              readOnly
              label="Πόντοι από την επαναχρησιμοποίηση"
              value={participant.data?.points_summary.reuse ?? ''}
            />
            <Form.Field
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <label>Πόντοι από τα τηγανέλαια</label>
              <Input
                readOnly
                value={participant.data?.points_summary.cookingoils ?? ''}
              />
            </Form.Field>
            <Form.Input
              readOnly
              label="Πόντοι που αξιοποιήθηκαν σε κουπόνια"
              value={participant.data?.points_summary.redeemed ?? ''}
            />
          </Form.Group>
        </Form>
      </Segment>
      <Divider hidden />
    </>
  );
}

export default function UsersAdmin() {
  const match = useRouteMatch();

  const pageTitle = 'Διαχείριση χρηστών';
  return (
    <>
      <Container>
        <Helmet title={pageTitle} />
        <Header
          as="h1"
          textAlign="center"
          className="colored atmgreen"
          content={pageTitle}
        />
        <Divider className="orange" />
        <Divider hidden />

        <Grid centered>
          <Grid.Row>
            <Grid.Column width={4}>
              <ParticipantsList
                parentPageTitle={pageTitle}
                parentMatch={match}
                // pathSuffix={barcodesActive ? '/barcodes' : ''}
                as="div"
                listHeight="50vh"
              />
            </Grid.Column>

            <Grid.Column width={12}>
              <Route path={`${match.path}/:participantId`}>
                <ParticipantDetails />
              </Route>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider section hidden />
      </Container>
    </>
  );
}
