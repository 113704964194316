import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import qs from 'qs';

export default function useReturn() {
  const history = useHistory();
  const location = useLocation();

  const { destination, next, cancelTo } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const wasCalledByClient = !!(destination || next);

  const handleReturn = useCallback(() => {
    if (cancelTo) {
      if (cancelTo.indexOf('http://') === 0 || cancelTo.indexOf('https://') === 0) {
        window.location = cancelTo;
      } else {
        history.replace(cancelTo);
      }
    } else if (wasCalledByClient) {
      history.goBack();
    }
  }, [history, wasCalledByClient, cancelTo]);

  const canReturn = cancelTo || wasCalledByClient;

  return canReturn ? handleReturn : null;
}
