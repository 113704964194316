import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useMutation } from 'react-query';

import {
  Button,
  Container,
  Divider,
  Form,
  Grid,
  Header,
  Image,
  List,
  Message,
  Segment,
} from 'semantic-ui-react';

import { useProfile } from '../queries/useProfile';
import useReturn from '../hooks/useReturn';

import axios from 'axios';
import qs from 'qs';

import Logo from '../images/DIADYMA_L.png';

export function Login({ header = 'Σύνδεση', passwordReset }) {
  const location = useLocation();
  const profile = useProfile();

  const handleReturn = useReturn();

  const [username, setUserName] = useState();
  const [password, setPassword] = useState();

  const login = useMutation(
    ({ username, password }) =>
      axios.post('/api/auth/token/login', { email: username, password }),
    {
      onSuccess: () => profile.refetch(),
    }
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    login.mutate({ username, password });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => login.reset(), [username, password]);

  return (
    <Container as="main" className="no-header">
      <Form
        size="large"
        className="no-header-ct"
        id="loginForm"
        onSubmit={handleSubmit}
        error={login.isError}
      >
        <Header as="h2" textAlign="center" content={header} />
        <Grid
          centered
          stackable
          as={Segment}
          style={{ fontSize: '1.14285714rem' }}
        >
          <Grid.Column width={6}>
            <Image centered src={Logo} />
            <Divider hidden />
            <p>
              Χρησιμοποιώντας το e-mail και το συνθηματικό σας και πατώντας
              Είσοδος θα έχετε πρόσβαση στις ψηφιακές υπηρεσίες της ΔΙΑΔΥΜΑ Α.Ε.
              ώστε:
            </p>
            <List relaxed bulleted className="decorated green">
              <List.Item>
                <span>να συγκεντρώνετε πόντους επιβράβευσης</span>
              </List.Item>
              <List.Item>
                <span>να εξαργυρώνετε τους πόντους σε προσφορές και δώρα.</span>
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column as={Divider} hidden only="mobile" />
          <Grid.Column as={Divider} only="mobile" />
          <Grid.Column width={10}>
            <Grid columns={1}>
              <Grid.Column>
                <Form.Input
                  icon="user"
                  iconPosition="left"
                  name="email"
                  label="Διεύθυνση email"
                  input={{ inputMode: 'email' }}
                  placeholder="Διεύθυνση email"
                  onChange={(e) => setUserName(e.target.value)}
                />
                <Form.Input
                  icon="lock"
                  iconPosition="left"
                  type="password"
                  name="password"
                  label="Συνθηματικό"
                  placeholder="Συνθηματικό"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Message
                  error
                  content="Αποτυχία σύνδεσης, παρακαλώ προσπαθήστε ξανά."
                />
              </Grid.Column>
              <Grid.Column textAlign="center">
                <div>
                  <Button
                    positive
                    form="loginForm"
                    className="positive-bt"
                    size="large"
                    content="Είσοδος"
                    loading={login.isLoading}
                    style={{
                      minWidth: '10em',
                    }}
                  />
                  {handleReturn && (
                    <Button
                      size="large"
                      content="Επιστροφή"
                      type="button"
                      onClick={handleReturn}
                      style={{
                        minWidth: '10em',
                      }}
                    />
                  )}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    paddingTop: '16px',
                    justifyContent: 'space-around',
                  }}
                >
                  <Link to={{ pathname: '/signup', search: location.search }}>
                    Δημιουργία νέου λογαριασμού
                  </Link>
                  {passwordReset && (
                    <Link
                      to={{ pathname: passwordReset, search: location.search }}
                    >
                      Ξέχασα το συνθηματικό μου
                    </Link>
                  )}
                </div>
              </Grid.Column>
            </Grid>
          </Grid.Column>
        </Grid>
      </Form>
    </Container>
  );
}

export function Logout({ onLogout }) {
  const location = useLocation();

  const { returnTo } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  useEffect(() => {
    onLogout(true, returnTo);
  }, [onLogout, returnTo]);

  return null;
}
