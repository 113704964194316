import { Helmet } from 'react-helmet';
import { Route, Switch, Redirect } from 'react-router-dom';

import { Container, Divider, Header, Loader } from 'semantic-ui-react';

import { useProfile } from '../queries/useProfile';

import HistoryAdmin from './HistoryAdmin';
import UsersAdmin from './UsersAdmin';
import ZerowasteContainer from './ZerowasteContainer';
import ZerowasteFooter from './ZerowasteFooter';
import { ParticipantForm } from './ParticipantSettings';

function AdminProfileSettings() {
  const pageTitle = 'Ρυθμίσεις λογαριασμού διαχειριστή';
  return (
    <>
      <Container>
        <Helmet title={pageTitle} />
        <Header
          as="h1"
          textAlign="center"
          className="colored atmgreen"
          content={pageTitle}
        />
        <Divider className="orange" />
        <Divider hidden />
        <Container text>
          <ParticipantForm baseUrl="/admin" inPage />
        </Container>

        <Divider section hidden />
      </Container>
    </>
  );
}

function ZerowasteAdmin({ onLogout }) {
  const profile = useProfile();

  // if we are some other user and want to go to admin, let's send them to login page
  if (profile.isFetched && (!profile.data || !profile.data.is_staff)) {
    return <Redirect to="/login" />;
  }

  return (
    <ZerowasteContainer onLogout={onLogout}>
      <Helmet defaultTitle="Διαχείριση | ΔΙΑΔΥΜΑ ΑΕ &ndash; Ψηφιακές Υπηρεσίες" />
      <main>
        {profile.isFetched ? (
          <Switch>
            <Route path="/admin" exact>
              <Redirect to="/admin/users" />
            </Route>

            <Route path="/admin/dashboard">
              <div></div>
            </Route>

            <Route path="/admin/users">
              <UsersAdmin />
            </Route>

            <Route path="/admin/points">
              <HistoryAdmin />
            </Route>

            <Route path="/admin/settings">
              <AdminProfileSettings />
            </Route>

            <Route path="*">
              <Redirect to="/admin" />
            </Route>
          </Switch>
        ) : (
          <Loader inline="centered" size="massive" active />
        )}
      </main>
      <ZerowasteFooter />
    </ZerowasteContainer>
  );
}

export default ZerowasteAdmin;
