import React, { Fragment, useState } from 'react';
import {
  Route,
  Link,
  NavLink,
  useLocation,
  useRouteMatch,
  matchPath,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';

import {
  List,
  Label,
  Menu,
  Message,
  Loader,
  Button,
  Divider,
} from 'semantic-ui-react';

import { SearchFilterInput, useDebounce } from '@zerowaste/components';

import { useParticipants, useParticipantsExport } from '../queries/participants';
import { usePointsSummary } from '../queries/useProfile';

export default function ParticipantsList({
  as: Root = Fragment,
  listHeight,
  parentPageTitle,
  parentMatch,
  pathSuffix = '',
  filter,
  ...props
}) {
  const match = useRouteMatch();
  const location = useLocation();
  const { search } = useLocation();

  const participantIsActive = !!matchPath(
    location.pathname,
    `${match.path}/:participant(\\d+)`
  );

  const [participantSearch, setParticipantSearch] = useState('');
  const debouncedParticipantSearch = useDebounce(participantSearch, 300);

  const participants = useParticipants({ search: debouncedParticipantSearch });
  const noParticipants =
    !debouncedParticipantSearch && participants.data?.length === 0;

  const pointsSummary = usePointsSummary();

  const totalRecords = pointsSummary.data?.transactions;

  const searchInput = (
    <SearchFilterInput
      loading={participants.isFetching}
      disabled={!debouncedParticipantSearch && participants.data?.length === 0}
      value={participantSearch}
      setValue={setParticipantSearch}
    />
  );

  // Export excel
  const exportExcel = useParticipantsExport();

  const handleExportData = () => {
    exportExcel.refetch().then((response) => {
      const file = new File([response.data], 'Χρήστες.xlsx', {
        type: response.data.type,
      });
      const href = URL.createObjectURL(file);
      window.location = href;
      URL.revokeObjectURL(href);
    });
  };

  return (
    <Root {...props}>
      {!filter && (
        <>
          <Menu secondary pointing size="huge">
            <Menu.Item as={NavLink} to={parentMatch.url} content="Χρήστες" />
          </Menu>
          <Button
            content="Εξαγωγή σε Excel"
            icon="download"
            onClick={handleExportData}
            loading={exportExcel.isFetching}
            disabled={exportExcel.isFetching}
            style={{ marginBottom: '1em' }}
            fluid
          />
          {searchInput}
          <Divider />
        </>
      )}

      <List selection size="large" className="narrow-list">
        {filter && (
          <>
            <List.Item
              as={Link}
              to={{ pathname: parentMatch.url, search }}
              active={!participantIsActive}
            >
              <List.Content floated="right">
                <Label
                  color="teal"
                  title={
                    totalRecords !== undefined
                      ? `${totalRecords} εγγραφές`
                      : undefined
                  }
                >
                  {totalRecords !== undefined ? totalRecords : <>&hellip;</>}
                </Label>
              </List.Content>
              <List.Header content="Όλοι οι χρήστες" />
            </List.Item>
            <List.Item>{searchInput}</List.Item>
          </>
        )}
      </List>

      <div
        style={{
          position: 'relative',
          height: listHeight,
          overflow: listHeight ? 'auto' : undefined,
        }}
      >
        {participants.isLoading && <Loader active />}
        {participants.isError && (
          <Message
            error
            content="Οι πληροφορίες για τους χρήστες δεν είναι διαθέσιμες."
          />
        )}
        {noParticipants && (
          <Message header="Δεν υπάρχουν εγγεγραμμένοι χρήστες." />
        )}

        <List selection divided className="narrow-list" size="large">
          {participants.data?.map(
            ({ id, full_name, email, points_transactions }) => (
              <Fragment key={id}>
                {/* Add user name to page title, by using a route-based head rendering method */}
                <Route path={`${parentMatch.path}/${id}`} exact>
                  <Helmet
                    title={`${
                      full_name || 'Άγνωστο όνομα'
                    } | ${parentPageTitle}`}
                  />
                </Route>
                <List.Item
                  key={id}
                  as={NavLink}
                  to={{
                    pathname: `${parentMatch.url}/${id}${pathSuffix}`,
                    search,
                  }}
                >
                  {filter && (
                    <List.Content floated="right">
                      <Label
                        color="teal"
                        content={points_transactions}
                        title={`${points_transactions} εγγραφές`}
                      />
                    </List.Content>
                  )}
                  <List.Content
                    title={`${full_name || 'Άγνωστο όνομα'} (${email})`}
                  >
                    <List.Header>{full_name || 'Άγνωστο όνομα'}</List.Header>
                    <List.Description>{email}</List.Description>
                  </List.Content>
                </List.Item>
              </Fragment>
            )
          )}
        </List>
      </div>
    </Root>
  );
}
