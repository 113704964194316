import { Route, Redirect, Switch, useLocation } from 'react-router-dom';

import ParticipantLanding from './ParticipantLanding';
import ParticipantSettings from './ParticipantSettings';

import { useProfile } from '../queries/useProfile';

import qs from 'qs';

export default function ZerowasteHome() {
  const location = useLocation();
  const profile = useProfile();

  // wait for it...
  if (!profile.isFetched) {
    return null;
  }

  // logged out, go to login!
  if (!profile.data) {
    return <Redirect to={{ pathname: '/login', search: location.search }} />;
  }

  // check if we need to redirect to oauth
  // get some redirect urls stuff first...
  const { destination, next } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const redirectTo = destination || next;

  // take care to full reload the page if going to oauth
  if (redirectTo?.startsWith('/oauth/')) {
    // we need this to do a full page reload for oauth to work
    window.location.href = redirectTo;
    return null;
  }

  // admin, check if visiting settings, otherwise go to admin page!
  if (profile.data.is_staff) {
    return (
      <Switch>
        <Route key="settings" path="/settings">
          <ParticipantSettings />
        </Route>
        <Route>
          <Redirect to="/admin" />
        </Route>
      </Switch>
    );
  }

  return (
    <Switch>
      <Route key="landing" path="/" exact>
        <ParticipantLanding />
      </Route>
      <Route key="settings" path="/settings">
        <ParticipantSettings />
      </Route>
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
  );
}
