import { useQuery } from 'react-query';

import axios from 'axios';

export const useParticipant = (participant, options) =>
  useQuery(
    ['participant', participant],
    () =>
      axios.get(`/api/participants/${participant}/`).then(({ data }) => data),
    {
      enabled: !!participant,
      refetchOnWindowFocus: false,
      ...options,
    }
  );

export const useParticipants = (params, options, queryName) =>
  useQuery(
    [queryName || 'participants', params],
    () => axios.get('/api/participants/', { params }).then(({ data }) => data),
    { keepPreviousData: true, ...options }
  );

export const useParticipantsExport = (params, options) =>
  useQuery(
    ['participantsExport', { params }],
    () =>
      axios
        .get('/api/participants/export/', { params, responseType: 'blob' })
        .then(({ data }) => data),
    {
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      ...options,
    }
  );
