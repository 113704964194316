import { useQuery } from 'react-query';

import axios from 'axios';

export const usePointsHistory = ({ params = {}, options = {} } = {}) =>
  useQuery(
    ['points', params],
    () => axios.get(`/api/points/`, { params }).then(({ data }) => data),
    { refetchOnWindowFocus: false, keepPreviousData: true, ...options }
  );

export const usePointsHistoryExport = (params, options) =>
  useQuery(
    ['pointsExport', params],
    () =>
      axios
        .get('/api/points/export/', { params, responseType: 'blob' })
        .then(({ data }) => data),
    {
      enabled: false,
      staleTime: 0,
      cacheTime: 0,
      ...options,
    }
  );

export const usePointsSummary = ({ params = {}, options = {} } = {}) =>
  useQuery(
    ['pointsSummary', params],
    () => axios.get(`/api/points/summary`, { params }).then(({ data }) => data),
    { refetchOnWindowFocus: false, ...options }
  );
