import {  NavLink, useLocation } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';

import { ResponsiveContainer } from '@zerowaste/components';

import { useProfile } from '../queries/useProfile';

import qs from 'qs';

export default function ZerowasteContainer({
  children,
  onLogout,
  showAuth = true,
}) {
  const profile = useProfile();
  const user = profile.data;

  const location = useLocation();
  const { code } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const handleLogout = () =>
    onLogout(
      ['/admin', '/profile', '/history', '/settings'].includes(
        window.location.pathname
      )
    );

  const userMenuItems = user && !user.is_staff && (
    <>
      <Menu.Item as={NavLink} to="/profile">
        Λογαριασμός
      </Menu.Item>
      <Menu.Item as={NavLink} to="/history">
        Ιστορικο
      </Menu.Item>
      <Menu.Item as={NavLink} to="/settings">
        Ρυθμίσεις
      </Menu.Item>
    </>
  );

  return (
    <ResponsiveContainer
      headerTitle="ΔΙΑΔΥΜΑ ΑΕ – Ψηφιακές Υπηρεσίες"
      className="header-menu"
      userPaths={['/profile', '/history']}
      contentPaths={['/']}
      user={user}
      userMenu={showAuth && userMenuItems}
      showLoginRegister={profile.isFetched && !code}
      onLogout={handleLogout}
      showAuth={showAuth}
      notLoggedIn={profile.isFetched && !profile.data}
      displaySettings={false}
      adminMenu={
        showAuth &&
        user?.is_staff && (
          <>
            <Menu.Item as={NavLink} to="/admin/users">
              Χρήστες
            </Menu.Item>
            <Menu.Item as={NavLink} to="/admin/points">
              Πόντοι
            </Menu.Item>
            <Menu.Item as={NavLink} to="/admin/settings">
              Ρυθμίσεις
            </Menu.Item>
          </>
        )
      }
    >
      {children}
    </ResponsiveContainer>
  );
}
