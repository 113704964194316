import { Card, Container, Grid, Header } from 'semantic-ui-react';

import { useProfile } from '../queries/useProfile';

export default function ParticipantLanding() {
  const profile = useProfile();

  if (profile.isFetching) {
    return null;
  }

  return (
    <main className="no-header">
      <Container style={{ fontSize: '1.14285714rem' }}>
        <Grid columns={3} stackable stretched>
          <Grid.Column>
            <Card
              fluid
              centered
              as="a"
              href={process.env.REACT_APP_COOKINGOILS_URL}
            >
              <Card.Header>
                <Header
                  textAlign="center"
                  block
                  size="huge"
                  className="action-header green"
                >
                  Ανακύκλωση τηγανέλαιων
                </Header>
              </Card.Header>
              <Card.Content text>
                <p style={{ color: '#373232' }}>
                  Κέρδισε πόντους εύκολα και γρήγορα, κάθε φορά που επιστρέφεις
                  μπουκάλια με χρησιμοποιημένα τηγανέλαια στα ειδικά σημεία
                  συλλογής (πράσινο ATM ή πορτοκαλί κάδος) που βρίσκονται σε
                  διάφορα σημεία στην περιφέρεια. Το αυτοκόλλητο με τον κωδικό
                  τοποθετείται στο καπάκι των ειδικών μπουκαλιών και όταν αυτά
                  γεμίσουν με τηγανέλαια, τα επιστρέφεις στα σημεία συλλογής,
                  από όπου θα μεταφερθούν σε ειδική εγκατάσταση για περαιτέρω
                  επεξεργασία. Όσο περισσότερα λίτρα επιστρέφεις, τόσο
                  περισσότερους πόντους συλλέγεις, ενώ ταυτόχρονα συμβάλλεις στη
                  βιώσιμη διαχείριση των αποβλήτων και στην προστασία του
                  περιβάλλοντος.
                </p>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card
              fluid
              centered
              as="a"
              href={process.env.REACT_APP_REUSE_URL}
            >
              <Card.Header>
                <Header
                  textAlign="center"
                  block
                  size="huge"
                  className="action-header blue"
                >
                  Επαναχρησιμοποίηση
                </Header>
              </Card.Header>
              <Card.Content>
                <p style={{ color: '#373232' }}>
                  Επαναχρησιμοποίησε υλικά από τα Κέντρα Δημιουργικής
                  Επαναχρησιμοποίησης Υλικών Δυτικής Μακεδονίας και μάζεψε
                  πόντους επιβράβευσης για κάθε υλικό που επαναχρησιμοποιείς. Με
                  την παράδοση ή την παραλαβή υλικού, το σύστημα συλλογής πόντων
                  σε επιβραβεύει με τους αντίστοιχους πόντους. Ο αριθμός των
                  πόντων εξαρτάται από το είδος της συναλλαγής, τα τεμάχια και
                  τον τύπο του υλικού.
                </p>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card
              fluid
              centered
              as="a"
              href={process.env.REACT_APP_REWARDS_URL}
            >
              <Card.Header>
                <Header
                  textAlign="center"
                  block
                  size="huge"
                  className="action-header orange"
                >
                  Εξαργύρωση πόντων
                </Header>
              </Card.Header>
              <Card.Content>
                <p style={{ color: '#373232' }}>
                  Αξιοποίησε τους πόντους σου ώστε να εκδόσεις κουπόνια τα οποία
                  μπορούν να εξαργυρωθούν σε συμβεβλημένα καταστήματα.
                </p>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid>
      </Container>
    </main>
  );
}
