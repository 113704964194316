import { useQuery } from 'react-query';

import axios from 'axios';


export function useProfile() {
  return useQuery(
    ['profile'],
    () => axios.get('/api/auth/users/me/').then(
      ({data}) => data,
      (err) => {
        console.warn(err.response.data?.detail);
        if (err.response?.status !== 401) throw err;
      }
    ), {
      enabled: false
    }
  );

}

export function usePointsSummary() {
  return useQuery(
    ['profile', 'points'],
    () => axios.get('/api/points/summary/').then(
      ({data}) => data,
      (err) => {
        console.warn(err.response.data?.detail);
        if (err.response?.status !== 401) throw err;
      }
    )
  );

}
