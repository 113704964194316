import { useState } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { useMutation } from 'react-query';

import {
  Header,
  Form,
  Message,
  Button,
  Divider,
  Container,
  Segment,
} from 'semantic-ui-react';

import { get as getCookie } from 'js-cookie';
import axios from 'axios';

function PasswordErrorMessage({ keywords, ...props }) {
  const messageLines = [],
    messageItems = [];

  if (keywords?.includes('password mismatch')) {
    messageLines.push(
      'Η επαλήθευση συνθηματικού δεν ταιριάζει με το συνθηματικό.'
    );
  }

  let passwordError = false;
  if (keywords?.includes('short password')) {
    messageItems.push(
      'Αυτό το συνθηματικό είναι πολύ μικρό. Πρέπει να περιέχει τουλάχιστον 8 χαρακτήρες.'
    );
    passwordError = true;
  }
  if (keywords?.includes('common password')) {
    messageItems.push('Πολύ κοινό συνθηματικό.');
    passwordError = true;
  }
  if (keywords?.includes('numeric password')) {
    messageItems.push('Αυτό το συνθηματικό αποτελείται μόνο απο αριθμούς.');
    passwordError = true;
  }
  if (
    keywords?.includes('similar password username') ||
    keywords?.includes('similar email password')
  ) {
    messageItems.push('Το συνθηματικό μοιάζει πολύ με τη διεύθυνση email.');
    passwordError = true;
  }
  if (keywords?.includes('similar password first name')) {
    messageItems.push('Το συνθηματικό μοιάζει πολύ με το όνομα.');
    passwordError = true;
  }
  if (keywords?.includes('similar password last name')) {
    messageItems.push('Το συνθηματικό μοιάζει πολύ με το επώνυμο.');
    passwordError = true;
  }

  if (keywords?.includes('invalid token')) {
    messageLines.push(
      'Ο κωδικός επαναφοράς συνθηματικού δεν ισχύει ή έχει λήξει.'
    );
    messageLines.push(
      <>
        Μπορείτε να ζητήσετε εκ νέου επαναφορά του συνθηματικού{' '}
        <Link to="/password/reset">εδώ</Link>.
      </>
    );
  }

  if (keywords?.includes('invalid current password')) {
    messageLines.push('Παρακαλώ εισαγάγετε το σωστό ισχύον συνθηματικό.');
  }

  let header = 'Αποτυχία αλλαγής συνθηματικού';
  if (passwordError) {
    header = 'Μη αποδεκτό συνθηματικό';
  }

  return (
    <Message {...props} error>
      <Message.Header>{header}</Message.Header>
      <Divider />
      {messageLines.length > 0 && (
        <p>
          {messageLines.map((content, index) => (
            <div key={index}>{content}</div>
          ))}
        </p>
      )}
      {messageItems.length > 0 && <Message.List items={messageItems} />}
    </Message>
  );
}

export function PasswordResetForm() {
  const location = useLocation();

  const [email, setEmail] = useState('');

  const resetMutation = useMutation(() =>
    axios.post('/api/auth/users/reset_password/' + location.search, { email })
  );

  const handleChange = (e, { value }) => setEmail(value);

  const handleSubmit = (e) => resetMutation.mutate();

  return (
    <main className="no-header">
      <Container text as={Segment} className="no-header-ct">
        <Header as="h2" textAlign="center" content="Επαναφορά συνθηματικού" />
        <Divider />
        <Form
          id="request-password-reset"
          success={resetMutation.isSuccess}
          error={resetMutation.isError}
          onSubmit={handleSubmit}
        >
          <Header size="small">
            Εισάγετε το e-mail σας και θα σας αποσταλεί σύνδεσμος για επαναφορά
            του συνθηματικού σας
          </Header>

          <Form.Input
            size="large"
            value={email}
            type="email"
            required
            readOnly={resetMutation.isSuccess}
            onChange={handleChange}
          />

          <Message success>
            <Message.Header>Επιτυχές αίτημα επαναφοράς</Message.Header>
            <Message.Content>
              Εφόσον έχετε λογαριασμό με το email που εισαγάγατε, θα σας σταλεί
              μήνυμα με έναν σύνδεσμο επαναφοράς του συνθηματικού σας.
            </Message.Content>
          </Message>

          <Message
            error
            header="Αποτυχία αιτήματος επαναφοράς συνθηματικού"
            content="Παρακαλώ προσπαθήστε ξανά αργότερα."
          />
        </Form>
        {!resetMutation.isSuccess && (
          <Button
            form="request-password-reset"
            primary
            size="large"
            content="Επαναφορά συνθηματικού"
            loading={resetMutation.isLoading}
            disabled={resetMutation.isLoading}
          />
        )}
        <Button
          size="large"
          content="Επιστροφή"
          as={Link}
          to={{ pathname: '/login', search: location.search }}
        />
      </Container>
    </main>
  );
}

export function PasswordChangeForm({ passwordReset = false, baseUrl = '', inPage }) {
  const { uid, token } = useParams();

  const location = useLocation();

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  const passwordMutation = useMutation(
    async ({ currentPassword, newPassword, repeatPassword, uid, token }) => {
      if (newPassword !== repeatPassword) {
        throw new Error('password mismatch');
      }

      const url = currentPassword
        ? '/api/auth/users/set_password/'
        : '/api/auth/users/reset_password_confirm/';
      try {
        const { data } = await axios.post(
          url,
          {
            uid,
            token,
            new_password: newPassword,
            current_password: currentPassword || undefined,
          },
          {
            headers: {
              'X-CSRFToken': getCookie('csrftoken'),
            },
          }
        );

        return data;
      } catch (error) {
        const tokenErrors = error.response.data['token'];
        if (tokenErrors?.includes('Invalid token for given user.')) {
          throw new Error('invalid token');
        }

        const authErrors = error.response.data['current_password'];
        if (authErrors?.includes('Invalid password.')) {
          throw new Error('invalid current password');
        }

        const passwordErrors = error.response.data['new_password'];
        if (passwordErrors) {
          let keywords = '';
          if (
            passwordErrors.includes(
              'This password is too short. It must contain at least 8 characters.'
            )
          ) {
            keywords += 'short password ';
          }
          if (passwordErrors.includes('This password is too common.')) {
            keywords += 'common password ';
          }
          if (passwordErrors.includes('This password is entirely numeric.')) {
            keywords += 'numeric password ';
          }

          // check for similarity
          for (const passwordError of passwordErrors) {
            const match = /The password is too similar to the (.+)/.exec(
              passwordError
            );
            const similarField = match?.[1];
            if (similarField) {
              keywords += `similar password ${similarField}`;
            }
          }

          throw new Error(keywords);
        }

        throw new Error(JSON.stringify(error.response.data));
      }
    }
  );

  const handleCurrentPasswordChange = (e, { value }) => {
    setCurrentPassword(value);
    passwordMutation.reset();
  };
  const handleNewPasswordChange = (e, { value }) => {
    setNewPassword(value);
    passwordMutation.reset();
  };
  const handleRepeatPasswordChange = (e, { value }) => {
    setRepeatPassword(value);
    passwordMutation.reset();
  };

  const handleSubmit = () =>
    passwordMutation.mutate({
      currentPassword,
      newPassword,
      repeatPassword,
      uid,
      token,
    });

  return (
    <>
      {!inPage && (
        <>
          <Header as="h2" textAlign="center" content="Αλλαγή συνθηματικού" />
          <Divider />
        </>
      )}

      <Segment secondary>
        <Form
          id="password-reset-confirm"
          onSubmit={handleSubmit}
          success={passwordMutation.isSuccess}
          error={passwordMutation.isError}
        >
          {!token && (
            <Form.Input
              value={currentPassword}
              label="Ισχύον συνθηματικό"
              type="password"
              required
              onChange={handleCurrentPasswordChange}
              readOnly={passwordMutation.isSuccess}
            />
          )}
          <Form.Input
            value={newPassword}
            label="Νέο συνθηματικό"
            type="password"
            required
            onChange={handleNewPasswordChange}
            readOnly={passwordMutation.isSuccess}
          />
          <Form.Input
            value={repeatPassword}
            label="Επαλήθευση νέου συνθηματικού"
            type="password"
            required
            onChange={handleRepeatPasswordChange}
            readOnly={passwordMutation.isSuccess}
          />

          <PasswordErrorMessage
            size="small"
            keywords={passwordMutation.error?.message}
          />

          <Message
            size="small"
            success
            header="Το συνθηματικό για τον λογαριασμό σας άλλαξε επιτυχώς"
            content="Μπορείτε τώρα να χρησιμοποίησετε το νέο συνθηματικό για να συνδεθείτε στην πλατφόρμα."
          />
        </Form>

        <Divider hidden />

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {passwordReset && passwordMutation.isSuccess && (
            <Button
              primary
              content="Σύνδεση"
              as={Link}
              to={{ pathname: '/login', search: location.search }}
            />
          )}
          {!passwordReset && (
            <Button
              secondary
              content="Ρυθμίσεις λογαριασμού"
              as={Link}
              to={{ pathname: `${baseUrl}/settings`, search: location.search }}
            />
          )}
          {!passwordMutation.isSuccess && (
            <Button
              form="password-reset-confirm"
              primary
              content="Αλλαγή συνθηματικού"
              loading={passwordMutation.isLoading}
              disabled={passwordMutation.isLoading}
            />
          )}
        </div>
      </Segment>
    </>
  );
}
