import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import {
  Form,
  Header,
  Grid,
  Button,
  Message,
  Divider,
  Container,
  Segment,
  Image,
  List,
} from 'semantic-ui-react';

import { ScrollToHere } from '@zerowaste/components';

import useReturn from '../hooks/useReturn';

import { get as getCookie } from 'js-cookie';
import axios from 'axios';

import Logo from '../images/DIADYMA_L.png';

function SignupErrorMessage({ keywords, ...props }) {
  const messageLines = [],
    messageItems = [];
  if (keywords?.includes('municipality required')) {
    messageLines.push('Παρακαλώ συμπληρώστε το πεδίο "Δήμος".');
  }

  if (keywords?.includes('email exists')) {
    messageLines.push(
      'Υπάρχει ήδη λογαριασμός που χρησιμοποιεί αυτήν την διεύθυνση email.',
      <>
        Μπορείτε να συνδεθείτε με την επιλογή <Link to="/login">ΣΥΝΔΕΣΗ</Link>
      </>
    );
  }
  if (keywords?.includes('invalid email')) {
    messageLines.push('Εισάγετε μια έγκυρη διεύθυνση email.');
  }

  let passwordError = false;
  if (keywords?.includes('password mismatch')) {
    messageLines.push(
      'Η επαλήθευση συνθηματικού δεν ταιριάζει με το συνθηματικό.'
    );
    passwordError = true;
  }

  if (keywords?.includes('short password')) {
    messageItems.push(
      'Αυτό το συνθηματικό είναι πολύ μικρό. Πρέπει να περιέχει τουλάχιστον 8 χαρακτήρες.'
    );
    passwordError = true;
  }
  if (keywords?.includes('common password')) {
    messageItems.push('Πολύ κοινό συνθηματικό.');
    passwordError = true;
  }
  if (keywords?.includes('numeric password')) {
    messageItems.push('Αυτό το συνθηματικό αποτελείται μόνο απο αριθμούς.');
    passwordError = true;
  }
  if (
    keywords?.includes('similar password username') ||
    keywords?.includes('similar password email')
  ) {
    messageItems.push('Το συνθηματικό μοιάζει πολύ με τη διεύθυνση email.');
    passwordError = true;
  }
  if (keywords?.includes('similar password first name')) {
    messageItems.push('Το συνθηματικό μοιάζει πολύ με το όνομα.');
    passwordError = true;
  }
  if (keywords?.includes('similar password last name')) {
    messageItems.push('Το συνθηματικό μοιάζει πολύ με το επώνυμο.');
    passwordError = true;
  }
  let header =
    'Παρουσιάστηκε κάποιο πρόβλημα κατά την δημιουργία του λογαριασμού σας';
  if (!messageLines.length && passwordError) {
    header = 'Μη αποδεκτό συνθηματικό';
  }

  return (
    <Message {...props} error>
      <Message.Header>{header}</Message.Header>
      <Divider />
      {messageLines.length > 0 && (
        <p>
          {messageLines.map((content, index) => (
            <div key={index}>{content}</div>
          ))}
        </p>
      )}
      {messageItems.length > 0 && <Message.List items={messageItems} />}
    </Message>
  );
}

function UserSignup() {
  const location = useLocation();
  const handleReturn = useReturn();

  const [signupParams, setSignupParams] = useState({});
  const handleChange = ({ target: { name, value } }) => {
    setSignupParams((prevParams) => ({ ...prevParams, [name]: value }));
  };

  const handleSelectionChange = (e, { name, value }) => {
    setSignupParams((prevParams) => ({ ...prevParams, [name]: value }));
  };

  const userMutation = useMutation(async () => {
    if (!signupParams.municipality) {
      throw new Error('municipality required');
    }

    if (
      !signupParams.password ||
      signupParams.password !== signupParams.re_password
    ) {
      throw new Error('password mismatch');
    }

    try {
      const { data } = await axios.post(
        '/api/auth/users/' + location.search,
        {
          ...signupParams,
          username: signupParams.email, // fix django requirement
        },
        {
          headers: {
            'X-CSRFToken': getCookie('csrftoken'),
          },
        }
      );
      return data;
    } catch (error) {
      // console.log(JSON.stringify(error.response.data));

      const emailErrors = error.response.data['email'];
      if (
        emailErrors?.includes('user with this email address already exists.')
      ) {
        // just simplify message, include some keywords / phrases to render the proper message in UI
        throw new Error('email exists');
      }
      if (emailErrors?.includes('Enter a valid email address.')) {
        throw new Error('invalid email');
      }

      const passwordErrors = error.response.data['password'];
      if (passwordErrors) {
        let keywords = '';
        if (
          passwordErrors.includes(
            'This password is too short. It must contain at least 8 characters.'
          )
        ) {
          keywords += 'short password ';
        }
        if (passwordErrors.includes('This password is too common.')) {
          keywords += 'common password ';
        }
        if (passwordErrors.includes('This password is entirely numeric.')) {
          keywords += 'numeric password ';
        }

        // check for similarity
        for (const passwordError of passwordErrors) {
          const match = /The password is too similar to the (.+)/.exec(
            passwordError
          );
          const similarField = match?.[1];
          if (similarField) {
            keywords += `similar password ${similarField}`;
          }
        }

        throw new Error(keywords);
      }

      throw new Error(JSON.stringify(error.response.data));
    }
  });

  const handleSubmit = () => userMutation.mutate();

  const { data: municipalities } = useQuery(
    'municipalities',
    () =>
      axios.get('/api/municipalities/').then(({ data }) => {
        // transform to options format
        return data.map((m) => ({ value: m.id, text: m.name }));
      }),
    { initialData: [] }
  );

  if (userMutation.isSuccess) {
    return (
      <Container text textAlign="center" lang="el">
        <ScrollToHere />
        <Divider hidden />
        <Message>
          <Message.Header>
            Τα στοιχεία του λογαριασμού σας έχουν αποθηκευτεί
          </Message.Header>
          <Message.Content>
            <Divider />

            <p>Ευχαριστούμε για το ενδιαφέρον στην πλατφόρμα μας.</p>

            <p>
              Για να ολοκληρωθεί η εγγραφή σας μένει μόνο να επιβεβαιώσετε την
              διέυθυνση email που μας δώσατε για την σύνδεσή στην πλατφόρμα.
            </p>

            <p>
              Στην διεύθυνση αυτή σας έχουμε στείλει ένα μήνυμα με οδηγίες για
              την ολοκλήρωση της διαδικασίας.
            </p>

            <Divider />

            {handleReturn && (
              <Button content="Επιστροφή" size="large" onClick={handleReturn} />
            )}
          </Message.Content>
        </Message>
        <Divider hidden />
      </Container>
    );
  }

  return (
    <Container as="main" className="no-header">
      <Form
        size="large"
        onSubmit={handleSubmit}
        error={userMutation.isError}
        className="no-header-ct"
      >
        <Header
          textAlign="center"
          as="h2"
          content="Δημιουργία λογαριασμού στις Ψηφιακές Υπηρεσίες της ΔΙΑΔΥΜΑ Α.Ε."
        />
        <Grid
          stackable
          lang="el"
          as={Segment}
          style={{ fontSize: '1.14285714rem' }}
        >
          <Grid.Row columns={3}>
            <Grid.Column>
              <Image centered src={Logo} />
              <Divider hidden />

              <p>
                Χρησιμοποιώντας το e-mail και το συνθηματικό σας και πατώντας
                Είσοδος θα έχετε πρόσβαση στις ψηφιακές υπηρεσίες της ΔΙΑΔΥΜΑ
                Α.Ε. ώστε:
              </p>

              <List bulleted className="decorated green">
                <List.Item>
                  <span>να συγκεντρώνετε πόντους επιβράβευσης</span>
                </List.Item>
                <List.Item>
                  <span>
                    να εξαργυρώνετε τους πόντους σε προσφορές και δώρα.
                  </span>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column as={Divider} hidden only="mobile" />
            <Grid.Column as={Divider} only="mobile" />
            <Grid.Column>
              <Form.Input
                required
                label="Όνομα"
                name="first_name"
                onChange={handleChange}
              />

              <Form.Input
                required
                label="Επώνυμο"
                name="last_name"
                onChange={handleChange}
              />

              <Form.Input
                required
                label="Τηλέφωνο"
                name="telephone"
                input={{ inputMode: 'tel' }}
                onChange={handleChange}
              />

              <Form.Dropdown
                required
                selection
                label="Δήμος"
                name="municipality"
                value={signupParams.municipality || null}
                onChange={handleSelectionChange}
                options={municipalities}
              />
            </Grid.Column>

            <Grid.Column>
              <Form.Input
                required
                label="Διεύθυνση email"
                name="email"
                input={{ inputMode: 'email' }}
                onChange={handleChange}
              />

              <Form.Input
                required
                label="Συνθηματικό"
                type="password"
                name="password"
                onChange={handleChange}
              />

              <Form.Input
                required
                label="Επαλήθευση συνθηματικού"
                type="password"
                name="re_password"
                onChange={handleChange}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row centered columns={1}>
            <Grid.Column textAlign="center">
              <Button
                size="large"
                positive
                className="positive-bt"
                loading={userMutation.isLoading}
                style={{
                  minWidth: '10em',
                }}
              >
                Δημιουργία νέου λογαριασμού
              </Button>
              {handleReturn && (
                <Button
                  size="large"
                  type="button"
                  onClick={handleReturn}
                  style={{
                    minWidth: '10em',
                    marginTop: '1em',
                  }}
                >
                  Επιστροφή
                </Button>
              )}
            </Grid.Column>

            <Grid.Column textAlign="center">
              <Segment basic size="large">
                <Link to={{ pathname: '/login', search: location.search }}>
                  Έχω λογαριασμό και θέλω να συνδεθώ
                </Link>
              </Segment>
            </Grid.Column>

            <Grid.Column textAlign="center" width={10}>
              <SignupErrorMessage
                size="small"
                keywords={userMutation.error?.message}
                onDismiss={() => userMutation.reset()}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </Container>
  );
}

export default UserSignup;
