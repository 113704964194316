import { Header, Image, List, Segment, Grid, Icon } from 'semantic-ui-react';

import diadymaImg from '../images/DIADYMA_L_WHITE.png';
import symbiolabsImg from '../images/SymbiolabsLogo.png';

function ZerowasteFooter() {
  return (
    <>
      <Segment basic className="footer-menu" inverted>
        <Grid
          doubling
          stackable
          container
          columns={4}
          style={{ marginTop: 0, marginBottom: 0 }}
        >
          <Grid.Column>
            <Image href="https://diadyma.gr" src={diadymaImg} />
          </Grid.Column>

          <Grid.Column>
            <Header className="footer-header" inverted>
              Σχεδιασμός και ανάπτυξη
            </Header>
            <Image
              size="small"
              href="https://symbiolabs.gr"
              src={symbiolabsImg}
            />
          </Grid.Column>

          <Grid.Column>
            <Header className="footer-header" inverted>
              Επικοινωνία
            </Header>
            <List
              items={[
                '6ο χλμ. Κοζάνης - Πτολεμαΐδας',
                'Κόμβος Εγνατίας',
                'Τ.Κ. 50 100 - Τ.Θ. 155',
              ]}
            />

            <List
              items={[
                {
                  key: 'tel',
                  icon: <Icon className="t green-icon" />,
                  content: '24610 45531 - 24610 45533',
                },
                {
                  key: '',
                  icon: <Icon className="f green-icon" />,
                  content: '24610 45532',
                },
              ]}
            />

            <List
              items={[
                {
                  key: 'email',
                  icon: <Icon name="mail outline" className="green-icon" />,
                  content: 'zerowaste@diadyma.gr',
                },
              ]}
            />
          </Grid.Column>
        </Grid>
      </Segment>
    </>
  );
}

export default ZerowasteFooter;
