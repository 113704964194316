import { useCallback, useEffect } from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';

import { Helmet } from 'react-helmet';

import { Container, Loader, Segment } from 'semantic-ui-react';

import 'semantic-ui-css/semantic.min.css';
import './App.css';

import { useProfile } from './queries/useProfile';

import { Login, Logout } from './components/Login';
import ZerowasteHome from './components/ZerowasteHome';
import ZerowasteAdmin from './components/ZerowasteAdmin';
import UserSignup from './components/UserSignup';

import { get as getCookie } from 'js-cookie';
import axios from 'axios';
import qs from 'qs';

import cookingOilsImg from './images/cookingoils-only.png';
import {
  PasswordChangeForm,
  PasswordResetForm,
} from './components/PasswordReset';
import ActivateAccount from './components/ActivateAccount';

function App() {
  const history = useHistory();
  const location = useLocation();

  const profile = useProfile();

  const handleLogout = useCallback(
    (privatePage, returnTo) => {
      // logout
      axios
        .post('/api/auth/token/logout/', null, {
          headers: {
            'X-CSRFToken': getCookie('csrftoken'),
          },
        })
        .finally(() => {
          if (returnTo) {
            window.location = returnTo;
          } else {
            // go to home to avoid re-login
            if (privatePage) history.push('/');
            profile.refetch();
          }
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history]
  );

  // logout logic
  const { logout: logoutOnMount } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  useEffect(() => {
    if (logoutOnMount) {
      // clear search string
      history.replace({
        search: '',
      });
      handleLogout();
    }
  }, [logoutOnMount, history, handleLogout]);

  // we need to fetch the profile once on mount to determine if we're logged in
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => profile.refetch(), []);

  // wait for it...
  if (!profile.isFetched) {
    return (
      <Loader
        style={{ marginTop: '100px' }}
        inline="centered"
        size="massive"
        active
      />
    );
  }

  const isLoggedOut = !profile.data && profile.isFetched;
  return (
    <>
      <Helmet
        defaultTitle="ΔΙΑΔΥΜΑ ΑΕ &ndash; Ψηφιακές Υπηρεσίες"
        titleTemplate="%s | ΔΙΑΔΥΜΑ ΑΕ &ndash; Ψηφιακές Υπηρεσίες"
      >
        <meta property="og:locale" content="el_GR" />
        <meta
          property="og:site_name"
          content="ΔΙΑΔΥΜΑ ΑΕ &ndash; Ψηφιακές Υπηρεσίες"
        />
        <meta property="og:image" content={cookingOilsImg} />
      </Helmet>
      <Switch>
        {isLoggedOut && [
          <Route key="login" path="/login">
            <Helmet title="Σύνδεση" />
            <Login
              passwordReset="/password/reset"
              header="Σύνδεση στις Ψηφιακές Υπηρεσίες της ΔΙΑΔΥΜΑ Α.Ε."
            />
          </Route>,
          <Route key="signup" path="/signup">
            <Helmet title="Δημιουργία νέου λογαριασμού" />
            <UserSignup />
          </Route>,
          <Route key="reset" exact path="/password/reset" component={PasswordResetForm} />,
          <Route key="reset_confirm" path="/password/reset/confirm/:uid/:token">
            <main className="no-header">
              <Container text as={Segment} className="no-header-ct">
                <PasswordChangeForm passwordReset />
              </Container>
            </main>
          </Route>,
          <Route key="activate"
            path="/account/activate/:uid/:token"
            component={ActivateAccount}
          />,
        ]}
        {/* logout route should be mounted and work regardless of login status */}
        <Route path="/logout">
          <Logout onLogout={handleLogout} />
        </Route>
        <Route path="/admin">
          <ZerowasteAdmin onLogout={handleLogout} />
        </Route>
        <Route>
          <ZerowasteHome />
        </Route>
      </Switch>
    </>
  );
}

export default App;
